<template>
  <div class="ReactModalPortal error-modal">
    <div class="ReactModal__Overlay ReactModal__Overlay--after-open" class-open="ReactModal__Overlay--after-open"
         style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(255, 255, 255, 0.75);"
         aria-modal="true">
      <div
        class="ReactModal__Content overlay xs-full-height xs-full-width xs-mb-0 xs-flex xs-items-center ReactModal__Content--after-open"
        class-open="ReactModal__Content--after-open" tabindex="-1" aria-label="DelayedOrderModal">
        <div class="xs-overflow-auto" style="width: 100%; height: 100%;">
          <button class="icon-close--white xs-p-20 xs-absolute xs-r-20 xs-t-10 close-error-popup"
                  @click="$emit('close')"></button>
          <div class="xs-pt-60 xs-px-40 xs-text-white xs-text-center">
            <p><span class="error-message bold" style="font-size: 20px;">{{$t('modal.delivery_message')}}</span></p>
            <p><span class="error-message bold " style="font-size: 18px;">{{$t('collection_hut_instead')}}</span></p>
          </div>
          <div class="xs-pt-60 xs-px-40 xs-text-white t16">
              <div class="xs-text-left " v-for="(outlet,i) in outletList " :key="i" style="cursor: pointer" @click="clickChosenOutlet(outlet), $emit('close')">
                <div class="flex justify-between items-center border-b border-dashed pl-10 pr-10 pt-20 pb-20">
                  <div >
                   <h4> <span class="text-capitalize">{{outlet.name}}{{$session.get('lang') ==='en' ? ' ': '' }} <span class="t12"> ( {{formatNum2Fix(outlet.distance)}} km )</span></span></h4>
                   <p>{{outlet.address}}</p>
                   <p v-if="outlet.zip">{{outlet.zip}}</p>
                  </div>
                  <button> <img src="../../assets/images/icons/arrow-right.svg" style="height: 16px"> </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['methodSearch', 'outletList'],
  methods: {
    /**
     * Trigger click clickChosenOutlet
     * */
    clickChosenOutlet: function (outlet) {
      this.$root.$emit('onclickChosenOutlet', outlet)
      this.$root.$emit('closeDrawMapModal')
    }
  }
}
</script>
<style >
  .v--modal-overlay .v--modal-box.carryout-modal {
    position: static !important;
  }
</style>
