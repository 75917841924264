<template>
  <div class="ReactModalPortal error-modal">
    <div class="ReactModal__Overlay ReactModal__Overlay--after-open" class-open="ReactModal__Overlay--after-open"
         style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(255, 255, 255, 0.75);"
         aria-modal="true">
      <div
        class="ReactModal__Content overlay xs-full-height xs-full-width xs-mb-0 xs-flex xs-items-center ReactModal__Content--after-open"
        class-open="ReactModal__Content--after-open" tabindex="-1" aria-label="DelayedOrderModal">
        <div class="xs-overflow-auto" style="width: 100%; height: 100%;">
          <button class="icon-close--white xs-p-20 xs-absolute xs-r-20 xs-t-10 close-error-popup"
                  @click="$emit('close')"></button>
          <div class="xs-pt-60 xs-px-40 xs-text-white xs-text-center">
            <img src="../../assets/images/icons/sorry-icon.svg" width="100" class="gingle-shake xs-my-40">
            <p>
              <span v-if="message" class="error-message bold"
                    style="font-size: 20px;">{{message}}</span>
              <span v-else class="error-message bold"
                    style="font-size: 20px;">{{$t('modal.delivery_message')}}</span>
            </p>
            <p>
            </p></div>

          <div class="xs-px-20 xs-py-20 xs-b-0 xs-l-0 xs-r-0 xs-fill-black-70 md-static md-fill-none col-sm-6" v-if="!hideCollectionButton">
            <button class="button button--green xs-text-center button-bottom" @click="collectionOutlet(methodSearch),$emit('close')">
              <span>{{$t('modal.collection_now')}}</span></button>
          </div>
          <div class="xs-px-20 xs-py-20 xs-b-0 xs-l-0 xs-r-0 xs-fill-black-70 md-static md-fill-none " v-bind:class="{ 'col-sm-6' : !hideCollectionButton}">
            <button class="button button--green xs-text-center button-bottom close-error-popup" @click=" $emit('close')">
              <span>{{$t('modal.choose_other_location')}}</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['message', 'methodSearch', 'hideCollectionButton'],
  methods: {
    /**
     * Trigger click tab take-away
     * */
    collectionOutlet: function (method) {
      if (method && method != this.$const.DEFAULT_CONFIG_APP) {
        this.$root.$emit('activeCollectionLocal')
      } else if (method && method != this.$const.DEFAULT_CONFIG_APP_2) {
        this.$root.$emit('activeCollectionMap')
      } else {
        this.$root.$emit('activeCollection')
      }
    }
  }
}
</script>
<style scoped>

</style>
