import { render, staticRenderFns } from "./ModalLocalizationSwitchConfirmation.vue?vue&type=template&id=7bb2dad8&scoped=true&"
import script from "./ModalLocalizationSwitchConfirmation.vue?vue&type=script&lang=js&"
export * from "./ModalLocalizationSwitchConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./ModalLocalizationSwitchConfirmation.vue?vue&type=style&index=0&id=7bb2dad8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb2dad8",
  null
  
)

export default component.exports