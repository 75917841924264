<template>
    <div class="modal-notify modal-content">
        <!-- <button @click="$emit('close')" type="button" data-dismiss="modal" aria-label="Close" class="close"
                style="width: 30px; height: 30px; border-radius: 30px; font-size: 30px; background: gray;"><span
                aria-hidden="true">×</span></button> -->
        <h2 class="modal-title title-coupons lg-cond text-center pt-10 pb-10">{{ $t('localization.delivery_switch_notice') }}</h2>
        <div class="free-menu-description section-content--free-menu t13 text-black text-center" style="margin-top: 0.5 em;">
            {{ $t('localization.delivery_not_available_nearest_store') }}
        </div>
        <div style="text-align: center;">
            <button type="button" style="width: 128px; margin-right: 0.5em;" @click="execute()"
                class="btn btn-success t16 fw600">{{$t('button_yes')}}
            </button>
            <button type="button" style="width: 128px; margin-left: 0.5em;" @click="$emit('close')"
                class="btn btn-danger t16 fw600">{{$t('close')}}
            </button>
        </div>
        <p /><p />
    </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {}
  },
  watch: {},
  mounted () {},
  methods: {
      execute() {
          this.$root.$emit('switchToCarryout')
          this.$emit('close')
      }
  }
}
</script>

<style scoped>
    .close {
        margin-top: 0;
    }

    button {
        margin: auto;
        width: 100%;
        margin-top: 20px;
    }

    .modal-notify {
        max-width: 400px;
        margin: auto;
        background: white;
        padding: 15px;
    }

    .alert-danger2 {
        color: #a94442;
        font-weight: bold;
    }

    .free-menu-description {
        cursor: pointer;
    }

    .v--modal-box {
        height: auto !important;
        top: 50px !important;
    }

    .modal-coupon .v--modal {
        min-width: 0px !important;
    }
</style>
