<template>
  <div class="modal-content modal-maps">
    <div class="">
      <button @click="$emit('close')" type="button" data-dismiss="modal" aria-label="Close" class="close"
              style="color: #fff;width: 35px; height: 35px;opacity: .6; border-radius: 35px; font-size: 35px; background: black;position: absolute; top: 20px;  z-index: 9999; right: 20px"><span
        aria-hidden="true">×</span></button>
      <div class="">
        <div id="mapLocalization" class="mt5" style="" ></div>
      </div>
      <div class="p10 t14 " style="position: relative;" >
        <div class="row">
          <div class="col-sm-9">
            <span v-if="!message">{{$t('delivery_title_notfound')}}</span>
            <span v-else class="text-red">{{message}}</span>
          </div>
          <div class="col-sm-3">
            <button class="button button--primary xs-width-auto min-w-150 m-auto text-white-hover" v-on:click="$root.$emit('triggerDeliverOrder')">{{$t('collect_now')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modalDrawMap',
  mounted () {
    var vm = this
    this.$root.$on('onSetMessageMap', (data) => {
      vm.message = data
    })
    this.$root.$on('closeDrawMapModal', () => {
      vm.$emit('close')
    })
  },
  props: [],
  data () {
    return {
      message: ''
    }
  },
  methods: {
  }
}
</script>
<style >
  .min-w-150{
    min-width: 120px;
  }
  #mapLocalization .gm-style>div:first-child>div:nth-child(3)>div:first-child>div:nth-child(4)>div:first-child>div:first-child>div:nth-child(3){
    border: unset;
  }
  .modal-maps button.close {
    background: #0000009c !important;
  }
  .draws-modal  #mapLocalization {
    /*height: 238px;*/
    width: 100%;
  }
  .draws-modal #mapLocalization {
    /*height: calc(100vh - 117px);*/
    height: calc(100vh - 190px) !important;
  }
  .modal-maps .vueperslide__content{
    padding-left: 10px;
  }
  @media (min-width: 768px) {
    .draws-modal #mapLocalization {
      /*height: calc(100vh - 177px);*/
      height: calc(100vh - 130px) !important;
    }
    .modal-maps .vueperslide__content{
      padding-left: 30px;
    }
  }
  .modal-maps .vueperslides__inner{
    width: 100%;
  }
  .modal-maps .vueperslide {
    border: 1px solid rgba(88, 84, 84, 0.19);
    border-radius: 4px;
    width: 80% !important;
  }
  .modal-maps  .vueperslide--active{
    border-top: 2px solid red;
  }
  .modal-maps  .vueperslide--active{
    margin-right: 10px;
  }
  .modal-maps .vueperslide--active.margin-left-10 {
    margin-left: 20%;
  }
  .modal-maps .vueperslide--active.margin-left-20 {
    margin-left: 40%;
  }
  .modal-maps .vueperslide--active.margin-left-30 {
    margin-left: 60%;
  }
  .modal-maps .vueperslide--active.margin-left-40 {
    margin-left: 80%;
  }
  .modal-maps .vueperslide--active.margin-left-50 {
    margin-left: 100%;
  }
  @media (max-width: 768px) {
    .v--modal {
      width: 100% !important;
      margin: auto !important;
    }
    .v--modal-overlay .v--modal-box{
      width: 100% !important;
    }
  }
  .carousel-control.left {
    top: 10px;
    margin-left: 10px !important;
    z-index: 1000;
  }
  .carousel-control.right {
    top: 10px;
    z-index: 1000;
    margin-right: 10px !important;
  }
  .carousel-control {
    width: 10px;
    color: #70a401;
    opacity: 1;
  }
</style>
